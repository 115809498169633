import { call, put, all, takeLatest } from 'redux-saga/effects';

import { handleError, startLoading, finishLoading } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchConsoleCommandLogList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchConsoleCommandLogList, params);
    yield put(actions.fetchConsoleCommandLogListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_CONSOLE_COMMAND_LOG_LIST, fetchConsoleCommandLogList),
  ]);
}
