import authPaths from './authPaths';
import clientPaths from './clientPaths';
import adminPaths from './adminPaths';
import storePaths from './storePaths';
import campaignPaths from './campaignPaths';
import productPaths from './productPaths';
import transactionPaths from './transactionPaths';
import couponPaths from './couponPaths';
import accessLogPaths from './accessLogPaths';
import consoleCommandLogPaths from './consoleCommandLogPaths';

export default {
  ...authPaths,
  ...clientPaths,
  ...adminPaths,
  ...storePaths,
  ...campaignPaths,
  ...productPaths,
  ...transactionPaths,
  ...couponPaths,
  ...accessLogPaths,
  ...consoleCommandLogPaths,
};
