import * as actionTypes from './actionTypes';

export const fetchConsoleCommandLogList = (params) => ({
  type: actionTypes.FETCH_CONSOLE_COMMAND_LOG_LIST,
  params,
});

export const fetchConsoleCommandLogListSuccess = (data) => ({
  type: actionTypes.FETCH_CONSOLE_COMMAND_LOG_LIST_SUCCESS,
  data,
});
