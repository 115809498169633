import { call, put, all, takeLatest } from 'redux-saga/effects';

import { handleError, startLoading, finishLoading } from 'ducks/app/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as services from './services';

function* fetchAccessLogList({ params }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchAccessLogList, params);
    yield put(actions.fetchAccessLogListSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

function* fetchAccessLog({ id }) {
  try {
    yield put(startLoading());
    const data = yield call(services.fetchAccessLog, id);
    yield put(actions.fetchAccessLogSuccess(data));
  } catch (e) {
    yield put(handleError(e));
  } finally {
    yield put(finishLoading());
  }
}

export default function* () {
  yield all([
    yield takeLatest(actionTypes.FETCH_ACCESS_LOG_LIST, fetchAccessLogList),
    yield takeLatest(actionTypes.FETCH_ACCESS_LOG, fetchAccessLog),
  ]);
}
