import { SUPER_ADMIN } from 'app/roles';

export default {
  accessLogs: {
    path: '/accessLogs',
    name: 'accessLogs:accessLogs',
    roles: [SUPER_ADMIN],
  },
  accessLogView: {
    path: '/accessLogs/:id',
    name: 'accessLogs:accessLogDetails',
    roles: [SUPER_ADMIN],
  },
};
