import { fromJS } from 'immutable';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  accessLogList: {
    order: [],
    items: {},
    meta: { page: 1, limit: 10, total: 0 },
  },
  viewingAccessLog: {},
});

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.FETCH_ACCESS_LOG_LIST_SUCCESS:
      return state.merge(fromJS({ accessLogList: data }));

    case actionTypes.FETCH_ACCESS_LOG_SUCCESS:
      return state.merge(fromJS({ viewingAccessLog: data }));

    default:
      return state;
  }
};
