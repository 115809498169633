import * as actionTypes from './actionTypes';

export const fetchAccessLogList = (params) => ({
  type: actionTypes.FETCH_ACCESS_LOG_LIST,
  params,
});

export const fetchAccessLogListSuccess = (data) => ({
  type: actionTypes.FETCH_ACCESS_LOG_LIST_SUCCESS,
  data,
});

export const fetchAccessLog = (id) => ({
  type: actionTypes.FETCH_ACCESS_LOG,
  id,
});

export const fetchAccessLogSuccess = (data) => ({
  type: actionTypes.FETCH_ACCESS_LOG_SUCCESS,
  data,
});
