import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'app/router';
import app from 'ducks/app';
import auth from 'ducks/auth';
import modals from 'ducks/modals';
import clients from 'ducks/clients';
import admins from 'ducks/admins';
import stores from 'ducks/stores';
import campaigns from 'ducks/campaigns';
import products from 'ducks/products';
import transactions from 'ducks/transactions';
import coupons from 'ducks/coupons';
import accessLogs from 'ducks/accessLogs';
import consoleCommandLogs from 'ducks/consoleCommandLogs';

export default combineReducers({
  router: connectRouter(history),
  app,
  auth,
  modals,
  clients,
  admins,
  stores,
  campaigns,
  products,
  transactions,
  coupons,
  accessLogs,
  consoleCommandLogs,
});
