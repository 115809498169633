import React, { memo } from 'react';

import { TextField } from 'components';

const RouteField = memo((props) => <TextField {...props} />);

RouteField.propTypes = {
  ...TextField.propTypes,
};

RouteField.defaultProps = {
  ...TextField.defaultProps,
  name: 'route',
  label: 'accessLogs:route',
};

export default RouteField;
