import React, { memo } from 'react';

import { TextField } from 'components';

const ActionField = memo((props) => <TextField {...props} />);

ActionField.propTypes = {
  ...TextField.propTypes,
};

ActionField.defaultProps = {
  ...TextField.defaultProps,
  name: 'action',
  label: 'accessLogs:action',
};

export default ActionField;
