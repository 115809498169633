import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { formatDateTime } from 'app/formatters';
import {
  actions as consoleCommandLogsActions,
  selectors as consoleCommandLogsSelectors,
} from 'ducks/consoleCommandLogs';
import { List, Breadcrumbs } from 'components';
import ConsoleCommandStatusCell from './ConsoleCommandStatusCell';

const rowConfig = [
  {
    name: 'commandName',
    source: 'commandName',
    label: 'consoleCommandLogs:commandName',
    sortable: true,
  },
  {
    name: 'status',
    label: 'consoleCommandLogs:status',
    sortable: true,
    component: ConsoleCommandStatusCell,
  },
  {
    name: 'startedAt',
    label: 'consoleCommandLogs:startedAt',
    render: ({ startedAt }) => formatDateTime(startedAt),
    sortable: true,
  },
  {
    name: 'endedAt',
    label: 'consoleCommandLogs:endedAt',
    render: ({ endedAt }) => formatDateTime(endedAt),
    sortable: true,
  },
  {
    name: 'message',
    source: 'message',
    label: 'consoleCommandLogs:message',
  },
];

const ConsoleCommandLogList = memo(() => {
  const { items, meta } = useSelector(consoleCommandLogsSelectors.consoleCommandLogList);
  const fetchConsoleCommandLogList = useAction(
    consoleCommandLogsActions.fetchConsoleCommandLogList,
  );

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchConsoleCommandLogList}
        rowConfig={rowConfig}
      />
    </>
  );
});

export default ConsoleCommandLogList;
