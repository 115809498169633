import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { mapAccessLog } from './entityMappers';

const accessLogSchema = new schema.Entity('accessLogs');
const accessLogListSchema = [accessLogSchema];

export const mapFetchAccessLogListRequest = (params) => {
  return mapListRequest(params, {});
};

export const mapFetchAccessLogListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'accessLogs',
    entityMapper: mapAccessLog,
    schema: accessLogListSchema,
  });
};

export const mapFetchAccessLogResponse = (data) => {
  return mapAccessLog(data);
};
