import React, { memo } from 'react';

import { Filter, ActionField, RouteField } from 'components';

const AccessLogFilter = memo(() => {
  return (
    <Filter
      initialValues={{
        action: '',
        route: '',
      }}
      mainField={<ActionField />}
    >
      <ActionField />
      <RouteField />
    </Filter>
  );
});

export default AccessLogFilter;
