import { schema } from 'normalizr';

import { mapListRequest, mapListResponse } from 'app/mappers';
import { mapConsoleCommandLog } from './entityMappers';

const consoleCommandLogSchema = new schema.Entity('consoleCommandLogs');
const consoleCommandLogListSchema = [consoleCommandLogSchema];

export const mapFetchConsoleCommandLogListRequest = (params) => {
  return mapListRequest(params, {});
};

export const mapFetchConsoleCommandLogListResponse = (data) => {
  return mapListResponse(data, {
    entityName: 'consoleCommandLogs',
    entityMapper: mapConsoleCommandLog,
    schema: consoleCommandLogListSchema,
  });
};

export const mapFetchConsoleCommandLogResponse = (data) => {
  return mapConsoleCommandLog(data);
};
