import { Map } from 'immutable';

import { createObjectSelector, createPaginatedListSelector } from 'app/selectors';

export const accessLogList = createPaginatedListSelector((state) =>
  state.accessLogs.get('accessLogList', Map()),
);

export const viewingAccessLog = createObjectSelector((state) =>
  state.accessLogs.get('viewingAccessLog', Map()),
);
