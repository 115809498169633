export const mapConsoleCommandLog = (data) => {
  const { id, commandName, message, startedAt, endedAt, status } = data;

  return {
    id: parseInt(id),
    commandName,
    message,
    startedAt,
    endedAt,
    status,
  };
};
