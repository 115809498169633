import axios from 'axios';

import * as mappers from './serviceMappers';

export function fetchAccessLogList(params) {
  return axios
    .get('access-logs/', { params: mappers.mapFetchAccessLogListRequest(params) })
    .then((response) => mappers.mapFetchAccessLogListResponse(response.data));
}

export function fetchAccessLog(id) {
  const scope = 'access_log_full';
  return axios
    .get(`access-logs/${id}`, { params: { scope } })
    .then((response) => mappers.mapFetchAccessLogResponse(response.data));
}
