import paths from 'app/paths';
import AccessLogList from 'components/accessLogs/AccessLogList/AccessLogList';
import AccessLogView from 'components/accessLogs/AccessLogView/AccessLogView';

export default [
  {
    path: paths.accessLogs.path,
    roles: paths.accessLogs.roles,
    component: AccessLogList,
  },
  {
    path: paths.accessLogView.path,
    roles: paths.accessLogView.roles,
    component: AccessLogView,
  },
];
