export const mapAccessLog = (data) => {
  const {
    id,
    route,
    action,
    createdAt,
    args,
    targetEntityClass,
    targetEntityId,
    posAgent,
    relatedCustomer,
    relatedUser,
    userAgent,
    offline,
  } = data;

  return {
    id: parseInt(id),
    route,
    action,
    createdAt,
    args,
    targetEntityClass,
    targetEntityId,
    offline,
    posAgent,
    relatedCustomer,
    relatedUser,
    userAgent,
  };
};
