import paths from 'app/paths';
import ConsoleCommandLogList from 'components/consoleCommandLogs/ConsoleCommandLogList/ConsoleCommandLogList';

export default [
  {
    path: paths.consoleCommandLogs.path,
    roles: paths.consoleCommandLogs.roles,
    component: ConsoleCommandLogList,
  },
];
