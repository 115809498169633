import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useAction } from 'app/hooks';
import { formatDateTime } from 'app/formatters';
import { actions as accessLogsActions, selectors as accessLogsSelectors } from 'ducks/accessLogs';
import { List, Breadcrumbs } from 'components';
import AccessLogActionsCell from './AccessLogActionsCell';
import AccessLogFilter from './AccessLogFilter';

const rowConfig = [
  {
    name: 'route',
    source: 'route',
    label: 'accessLogs:route',
    sortable: true,
  },
  {
    name: 'action',
    source: 'action',
    label: 'accessLogs:action',
    sortable: true,
  },
  {
    name: 'createdAt',
    label: 'app:date',
    render: ({ createdAt }) => formatDateTime(createdAt),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: AccessLogActionsCell,
  },
];

const AccessLogList = memo(() => {
  const { items, meta } = useSelector(accessLogsSelectors.accessLogList);
  const fetchAccessLogList = useAction(accessLogsActions.fetchAccessLogList);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchAccessLogList}
        filterComponent={AccessLogFilter}
        rowConfig={rowConfig}
      />
    </>
  );
});

export default AccessLogList;
