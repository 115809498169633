import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs, Panel, PropertyRow } from 'components';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../app/formatters';
import { useAction } from '../../../app/hooks';
import {
  actions as accessLogActions,
  selectors as accessLogsSelectors,
} from '../../../ducks/accessLogs';

const AccessLogView = memo((props) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { id },
    },
  } = props;
  const accessLogId = parseInt(id);
  const fetchAccessLog = useAction(accessLogActions.fetchAccessLog);
  const accessLog = useSelector(accessLogsSelectors.viewingAccessLog);

  useEffect(() => {
    fetchAccessLog(accessLogId);
  }, [accessLogId, fetchAccessLog]);

  const { posAgent, relatedCustomer, relatedUser, userAgent, args } = accessLog;

  return (
    <>
      <Breadcrumbs />
      <Panel title="accessLogs:generalInfo" defaultExpanded>
        <PropertyRow label="accessLogs:route">{accessLog.route}</PropertyRow>
        <PropertyRow label="accessLogs:action">{accessLog.action}</PropertyRow>
        <PropertyRow label="app:date">{formatDateTime(accessLog.createdAt)}</PropertyRow>
        <PropertyRow label="accessLogs:targetEntityClass">
          {accessLog.targetEntityClass}
        </PropertyRow>
        <PropertyRow label="accessLogs:targetEntityId">{accessLog.targetEntityId}</PropertyRow>
        <PropertyRow label="accessLogs:offline">{String(accessLog.offline)}</PropertyRow>
      </Panel>
      {args && (
        <Panel title="accessLogs:args" defaultExpanded={false}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('accessLogs:argName')}</TableCell>
                <TableCell>{t('accessLogs:argValueOld')}</TableCell>
                <TableCell>{t('accessLogs:argValueNew')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(args).map(([key, value]) => {
                return (
                  <TableRow key={`${key}`}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{JSON.stringify(value[0])}</TableCell>
                    <TableCell>{JSON.stringify(value[1])}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Panel>
      )}
      {relatedCustomer && (
        <Panel title="accessLogs:relatedCustomer" defaultExpanded={false}>
          <PropertyRow label="app:id">{relatedCustomer.id}</PropertyRow>
          <PropertyRow label="app:firstName">{relatedCustomer.firstName}</PropertyRow>
          <PropertyRow label="app:lastName">{relatedCustomer.lastName}</PropertyRow>
          <PropertyRow label="app:phoneNumber">{relatedCustomer.mobileNumber}</PropertyRow>
        </Panel>
      )}
      {relatedUser && (
        <Panel title="accessLogs:relatedUser" defaultExpanded={false}>
          <PropertyRow label="app:id">{relatedUser.id}</PropertyRow>
          <PropertyRow label="auth:email">{relatedUser.email}</PropertyRow>
          <PropertyRow label="app:username">{relatedUser.username}</PropertyRow>
        </Panel>
      )}
      {posAgent && (
        <Panel title="accessLogs:posAgent" defaultExpanded={false}>
          <PropertyRow label="stores:posNumber">{posAgent.number}</PropertyRow>
          <PropertyRow label="stores:store">{posAgent.store.name}</PropertyRow>
          <PropertyRow label="stores:storeNumber">{posAgent.store.number}</PropertyRow>
        </Panel>
      )}
      {userAgent && (
        <Panel title="accessLogs:userAgent" defaultExpanded={false}>
          <PropertyRow label="app:id">{userAgent.id}</PropertyRow>
          <PropertyRow label="auth:email">{userAgent.email}</PropertyRow>
          <PropertyRow label="app:username">{userAgent.username}</PropertyRow>
        </Panel>
      )}
    </>
  );
});

AccessLogView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default AccessLogView;
