import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConsoleCommandStatusCell = memo(({ item: { status } }) => {
  const { t } = useTranslation();

  switch (status) {
    case '0':
      return <>{t('app:success')}</>;
    case '1':
      return <>{t('app:error')}</>;
    default:
      return <>{status}</>;
  }
});

ConsoleCommandStatusCell.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default ConsoleCommandStatusCell;
